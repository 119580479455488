








































































import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import PasswordsMixin from "@/mixins/http/PasswordsMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import { RequestData } from "@/store/types/RequestStore";
import { RegisterHttp } from "@/utils/Decorators";
import { showErrorToast, showToast } from "@/utils/Toast";
import Credits from "@/components/Credits.vue";
import { easync } from "@/utils/http";

@Component({ components: { DisplayErrors, Credits } })
export default class ResetPassword extends Mixins(PasswordsMixin) {
  private password = "";

  @RegisterHttp("reset") http: RequestData;

  get resetToken() {
    return this.$route.query.token as string;
  }

  get passwordValid() {
    return this.password.length >= 8;
  }

  async submit() {
    const data = {
      token: this.resetToken,
      password: this.password
    };

    const [res, errors] = await easync(
      this.resetPassword(data, "reset")
    );

    if (errors) {
      showErrorToast("C'è stato un errore con il reset della password. Questo link potrebbe non essere più valido");
      return;
    }

    showToast(`Password resettata. Ora accedi!`);
  }
}
